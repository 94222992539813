const defaultState = (localStorage.getItem("SIDEBAR_COLLAPSED") === "true");
const sidebarCollapsed = (state = defaultState, action) => {  
    switch (action.type) {
        case 'SIDEBAR_COLLAPSED':
            return action.collapsed
        default:
            return state
    }
}

export default sidebarCollapsed