// Apex Types
// Event Action Types
export const ADD_EVENT = "ADD_EVENT";

// Chat Action Types
export const HANDLE_FILTER_USER = "HANDLE_FILTER_USER";
export const HANDLE_SELECT_USER = "HANDLE_SELECT_USER";
export const HANDLE_SEND_MESSAGE = "HANDLE_SEND_MESSAGE";

// Optimize100x Types
export const USER_SET = "user_set";
export const USER_CLEAR = "user_clear";

// Auth Types
// export const AUTH_USER = "auth_user";
// export const AUTH_UPDATE = "auth_update";
// export const AUTH_ERROR = "auth_error";
// export const AUTH_CLEAR_MSG = "auth_clear_message";

// // User Profile Types
// export const USERPROFILE_FIND = "userprofile_find";
// export const USERPROFILE_UPDATE = "userprofile_update";
// export const USERPROFILE_ERROR = "userprofile_error";
// export const USERPROFILE_CLEAR = "userprofile_clear"; // clears all data in userprofile global state, used for logouts
// export const USERPROFILE_FULLSCREEN = "userprofile_fullscreen";
// export const USERPROFILE_POINTS_FIND = "userprofile_points_find";
// export const USERPROFILE_POINTS_UPDATE = "userprofile_points_update";
// export const RVM_APPROVAL_EMAIL = "rvm_approval_email";

// // Table Filter Types
// export const ACCOUNTS_TABLE_FILTERS = "accounts_table_filters";
// export const OPPORTUNITIES_TABLE_FILTERS = "opportunities_table_filters";
// export const LEADS_TABLE_FILTERS = "leads_table_filters";
// export const VENDORS_TABLE_FILTERS = "vendors_table_filters";
// export const IMPORTS_TABLE_FILTERS = "import_table_filters";
// export const ORDER_FULFILLMENT_TABLE_FILTERS = "order_fulfillment_table_filters";
// export const RVM_CAMPAIGNS_TABLE_FILTERS = "rvm_campaigns_table_filters";

// // Analytics Types
// export const ANALYTICS_BUSINESS_DASHBOARD_FILTERS = "analytics_business_dashboard_filters";
// export const ANALYTICS_LEAD_DETAILS_DASHBOARD_FILTERS = "analytics_lead_details_dashboard_filters";
// export const ANALYTICS_FETCH = "analytics_fetch";
// export const ANALYTICS_INTERNAL_KPI = "analytics_interternal_kpi";

// // Quick Search
export const QUICK_SEARCH = "quick_search";

// // Table Sort
// export const TABLE_SORT = "table_sort";
