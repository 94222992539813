const defaultState = localStorage.getItem("BG_IMAGE_URL") || "";
const sidebarImageUrl = (state = defaultState, action) => {  
    switch (action.type) {
        case 'BG_IMAGE_URL':
            return action.imgurl
        default:
            return state
    }
}

export default sidebarImageUrl