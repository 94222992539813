const defaultState = localStorage.getItem("SIDEBAR_SIZE") || "sidebar-md";
const sidebarSize = (state = defaultState, action) => {  
    switch (action.type) {
        case 'SIDEBAR_SIZE':
            return action.size
        default:
            return state
    }
}

export default sidebarSize