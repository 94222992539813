const defaultState = localStorage.getItem("BG_COLOR") || "";
const sidebarBgColor = (state = defaultState, action) => {  
    switch (action.type) {
        case 'BG_COLOR':
            return action.color
        default:
            return state
    }
}

export default sidebarBgColor