const defaultState = localStorage.getItem("BG_IMAGE") || "";
const sidebarImage = (state = defaultState, action) => {  
    switch (action.type) {
        case 'BG_IMAGE':
            return action.img
        default:
            return state
    }
}

export default sidebarImage