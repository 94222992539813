import { USER_SET, USER_CLEAR } from "../../types";

const defaultState = {
  token: ""
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case USER_SET:
      return { ...state, ...action.payload };
    case USER_CLEAR:
      return defaultState;
    default:
      return state;
  }
};
